import { useEffect, useRef } from "react";
import Element from "./Element";

function Slide({
  slide,
  currentTime,
  playing,
  location,
  player,
  lang,
  params,
  creative,
  isActive,
  setActiveSlide,
  creativeState,
  setCreativeState
}) {
  const style = { opacity: isActive ? 1 : 0, backgroundColor: "#000" };

  const timer = useRef(0); 

  useEffect(() => {
    if (isActive && slide.interactiveVisibility?.duration > 0){
      const {duration, targetSlide } = slide.interactiveVisibility
      timer.current = setTimeout(() => {
        setActiveSlide(targetSlide - 1)
      }, duration * 1000);
      console.log(`Timeout ${timer.current} para slide ${slide.name}`)
    }

    if (!isActive && timer.current){
      clearTimeout(timer.current)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [isActive, setActiveSlide, slide.interactiveVisibility, slide.name])
  
  return (
    <div className="slide" style={style}>
      {slide.elements.map((element) => {
        return (
          <Element
            key={element.anchor}
            element={element}
            currentTime={currentTime}
            playing={playing}
            player={player}
            location={location}
            lang={lang}
            params={params}
            creative={creative}
            setActiveSlide={setActiveSlide}
            isSlideActive={isActive}
            creativeState={creativeState}
            setCreativeState={setCreativeState}
          />
        );
      })}
    </div>
  );
}

export default Slide;
