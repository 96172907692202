/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react"
import { startCameraVideo } from "helpers"
import { getStyles } from "helpers/cameraFilterInput"

function CameraInputFilterElement({ element }) {
  const videoRef = useRef()
  const { style, config } = element

  useEffect(() => {
    const video = videoRef.current

    startCameraVideo(video)
  }, [])

  return (
    <div style={style}>
      <video
        ref={videoRef}
        css={getStyles(config.rotation, config.mirror, style)}
      ></video>
    </div>
  )
}

export default CameraInputFilterElement
