import React, { useState, useEffect } from "react";

function TimerElement({ element, playing, visible }) {
  const { style, config } = element;

  const { duration } = config;

  const [seconds, setSeconds] = useState(duration)

  useEffect(() => {
    if (seconds === undefined || !(playing && visible)) return setSeconds(duration);

    if (seconds === 0) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);

  }, [seconds, playing, visible]);

  return <div className="countdown" style={style}>00:{seconds.toString().padStart(2, '0')}</div>;
}

export default TimerElement;
