import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { sendRegister } from '../services/player';

import "./MemoryCardElement.css";

const DEFAULT_URL = 'https://www.googleapis.com/download/storage/v1/b/d3ck-development/o/hndb22m8l09p118x.png?generation=1646233680729862&alt=media';

function Card({ url, isFinished, isChecked, addToCheckedItems, setClicks, clicks }) {
    const isFinishedOrChecked = isFinished || isChecked

    function onClick() {
        console.log('click card')
        setClicks(clicks + 1)
        !isFinished && !isChecked && addToCheckedItems();
    }

    return (<div className={isFinished ? 'memory-grid-card-container' : ''}>
        <img className='memory-grid-card' src={isFinishedOrChecked ? url : DEFAULT_URL} alt={`Memory card ${url}`} onClick={onClick} />
    </div>
    )
}

function MemoryCards({ element, setActiveSlide, isSlideActive, player }) {
    const [finishedItems, setFinishedItems] = useState([])
    const [checkedItems, setCheckedItems] = useState([])
    const [counter, setCounter] = React.useState(60);
    const [isGameOver, setIsGameOver] = useState(false)
    const [shuffledCards, setShuffledCards] = useState([])
    const [isWin, setIsWin] = useState(undefined)
    const [clicks, setClicks] = useState(0)
    const cards = useMemo(() => ([...element.memoryUrls, ...element.memoryUrls].sort((a, b) => 0.5 - Math.random())), [])
    
    const { style } = element;
    const { width, ...styleProps } = style;

    const restartState = useCallback(( ) => {
        setCheckedItems([])
        setFinishedItems([])
        setIsGameOver(false)
        setClicks(0)
        setShuffledCards(shuffledCards.sort((a, b) => 0.5 - Math.random()))
        setCounter(60)
        setIsWin(undefined)
    }, [shuffledCards])

    useEffect(() => {
        setShuffledCards(cards)
    }, [cards])

    useEffect(() => {

        if (cards.length && finishedItems.length && finishedItems.length === cards.length) {
            setIsGameOver(true)
        }
    }, [cards, finishedItems])

    useEffect(( ) => {
        if (checkedItems.length === 2) {
            shuffledCards[checkedItems[0]] === shuffledCards[checkedItems[1]] && setFinishedItems([...new Set([...finishedItems, checkedItems[0], checkedItems[1]])])
            setTimeout(() => {
                setCheckedItems([])
            }, 600);
        }

        if (isGameOver && isWin === undefined){
            setIsWin(false)
            sendRegister({ didWin: true, attempts: clicks, player: player?.bsPlayerId })
            setTimeout(() => {
                setActiveSlide(3)
                restartState()
            }, 2000);
        }

    }, [checkedItems, finishedItems, shuffledCards, setActiveSlide, restartState, isGameOver, clicks, isWin, player])

    useEffect(() => {
        if(counter === 0 && isWin === undefined){

            setIsWin(false)
            sendRegister({ didWin: false, attempts: clicks, player: player?.bsPlayerId })
            setTimeout(() => {
                setActiveSlide(4)
                restartState()
            }, 2000);
        }
        if (isSlideActive){
            const timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, setActiveSlide, isSlideActive, restartState, clicks, isWin, player]);

    return <div className='memory-grid-container memory-grid-container-right' style={styleProps}>
        {
            (counter === 0 || isGameOver) &&
            <img alt="boom" src="https://www.googleapis.com/download/storage/v1/b/d3ck-development/o/hndb22m8l09n6rd5.gif?generation=1646230590701709&alt=media" className="boom"/>
        }
        <p className="counter-text">TE QUEDAN: {counter}s</p>
        <div className='memory-grid' style={styleProps}>
            {shuffledCards && shuffledCards.map((card, index) => {
                function addToCheckedItems() {
                    return checkedItems.length < 2 && setCheckedItems([...checkedItems, index])
                }
                return (<Card key={index} url={card} isFinished={finishedItems.includes(index)} clicks={clicks} setClicks={setClicks} isChecked={checkedItems.includes(index)} addToCheckedItems={addToCheckedItems} />)
            })}
        </div>
    </div>
}
  
export default MemoryCards;
  