import { parse } from "query-string";
import { checkVisibility } from "d3ck-lib";

export function transformCreative(player = {}, style = {}) {
  const rotated = isRotated() || (player && player.rotate);
  const doubled = isDoubled() || (player && player.double);
  const { height, width } = style;

  if (player.scale && rotated) {
    
    if (player.bsPlayerId === "Channel vi_stjea_jeiex_1_1") {
      return {
        ...style,
        ...{
          transformOrigin: "left top",
          transform: `scale(${player.scale}) rotate(-90deg) translateX(-100%)`,
          float: "left",
          position: "absolute",
        },
      };
    } else {
      return {
      ...style,
      ...{
        transformOrigin: "bottom left",
        transform: `scale(${player.scale}) rotate(-90deg) translateY(100%)`,
        float: "left",
        position: "absolute",
        bottom: "0",
      },
    };
    }
  } else if (player.scale) {
    return {
      ...style,
      ...{
        transformOrigin: "top left",
        transform: `scale(${player.scale})`,
        float: "left",
      },
    };
  } else if (rotated && doubled) {
    const translationX = -(100 + ((height - width) * 100) / 2 / width);
    return {
      ...style,
      ...{
        transformOrigin: "100% 0",
        transform: `translateX(${translationX}%) rotate(-90deg)`,
        float: "left",
      },
    };
  } else if (rotated) {
    return {
      ...style,
      ...{
        transformOrigin: "100% 0",
        transform: `translateX(-100%) rotate(-90deg)`,
        float: "left",
      },
    };
  }
  return style;
}

export function isDoubled() {
  const query = parse(window.location.search);
  return window.double || query.double !== undefined;
}

export function isRotated() {
  const query = parse(window.location.search);
  return window.rotate || query.rotate !== undefined;
}

export function hasSyncVideo(creativity) {
  const { slides } = creativity;

  for (let s = 0; s < slides.length; s++) {
    const { elements } = slides[s];

    for (let e = 0; e < elements.length; e++) {
      const element = elements[e];

      if (element.type === "video" && element.sync === true) {
        return true;
      }
    }
  }

  return false;
}

const calcVisibilityDuration = (vis) => {
  return vis.duration + (vis.enterDuration || 0) + (vis.exitDuration || 0);
};

const calcElementDuration = (element) => {
  if (!element.visibility.duration) {
    return Infinity;
  }

  return calcVisibilityDuration(element.visibility);
};

const getVisibilityQuery = () => {
  const query = parse(window.location.search);

  return { date: query.dateTime, traffic: query.traffic };
};

const caclSlidesTimeline = (
  slides,
  location,
  trafficEstimate,
  sport,
  trafficGrabber,
  languages,
  forms,
  weathers
) => {
  const query = getVisibilityQuery();
  const filter = checkVisibility.bind(this, {
    location,
    trafficEstimate,
    sport,
    trafficGrabber,
    languages,
    forms,
    weathers,
    query,
  });
  let time = 0;

  return slides.filter(filter).map((slide) => {
    let slideExit = Infinity;
    let st = time,
      et = time;
    const elements = slide.elements.filter(filter).map((element) => {
      const ev = element.visibility;

      et += ev.enterDelay || 0;
      element = {
        ...element,
        timeline: {
          enter: {
            time: et,
            transition: ev.enterTransition,
            duration: ev.enterDuration,
          },
          exit: {
            time: et + calcElementDuration(element) - (ev.exitDuration || 0),
            transition: ev.exitTransition,
            duration: ev.exitDuration || 0,
          },
        },
      };

      return element;
    });

    if (elements.length) {
      const lt = elements[elements.length - 1].timeline;
      const sv = slide.visibility;

      if (sv.duration) {
        const slideDuration = sv.duration + (sv.enterDuration || 0);

        slideExit = st + slideDuration;
        time += slideDuration + (sv.exitDuration || 0);
      } else if (lt.exit) {
        slideExit = lt.exit.time + lt.exit.duration - (sv.exitDuration || 0);
        time = lt.exit.time + lt.exit.duration + (sv.exitDuration || 0);
      }
    }

    slide = {
      ...slide,
      elements: elements,
      timeline: {
        enter: {
          time: st,
          transition: slide.visibility.enterTransition,
          duration: slide.visibility.enterDuration,
        },
        exit: {
          time: slideExit,
          transition: slide.visibility.exitTransition,
          duration: slide.visibility.exitDuration,
        },
      },
    };

    return slide;
  });
};

export function caclTimeline(
  creativity,
  geoLocation,
  trafficEstimate,
  sport,
  trafficGrabber,
  languages,
  forms
) {
  const slides = caclSlidesTimeline(
    creativity.slides,
    geoLocation,
    trafficEstimate,
    sport,
    trafficGrabber,
    languages,
    forms,
    creativity.weather
  );
  const lastTimeline = slides.length
    ? slides[slides.length - 1].timeline
    : {
        exit: { time: 0, duration: 0 },
      };

  return {
    ...creativity,
    slides: slides,
    duration:
      lastTimeline.exit.time +
      (lastTimeline.exit.duration ? lastTimeline.exit.duration : 0),
  };
}

function interpolate(transition, time, start, duration, initial, change) {
  if (transition === "fade" && duration) {
    return (change * (time - start)) / duration + initial;
  } else {
    return initial + change;
  }
}

export function interpolateOpacity(currentTime, timeline = { enter: { time: 0 }}) {
  if (timeline.exit) {
    if (currentTime >= timeline.exit.time) {
      const { transition, time, duration } = timeline.exit;

      return interpolate(transition, currentTime, time, duration, 1, -1);
    }
  }

  if (currentTime >= timeline.enter.time) {
    const { transition, time, duration } = timeline.enter;

    return interpolate(transition, currentTime, time, duration, 0, 1);
  }

  return 0;
}

export function getTextContent({
  content,
  defaultText = "",
  prefix = "",
  suffix = "",
}) {
  if (typeof content === "string" || typeof content === "number") {
    return `${prefix}${content || defaultText}${suffix}`;
  }

  return content;
}


export function isPreview() {
  return document.location.pathname.indexOf("/preview") === 0;
}

export function getPreviewCorners() {
  const baseWidth = 5228;
  // const baseHeight = 3484;
  const currentWidth = window.innerWidth;
  // const currentHeight = window.innerHeight;
  const scale = currentWidth / baseWidth;

  return {
    x1: 3402 * scale,
    y1: 442 * scale,
    x2: 4480 * scale,
    y2: 274 * scale,
    x3: 3402 * scale,
    y3: 2136 * scale,
    x4: 4480 * scale,
    y4: 2194 * scale,
  };
}

export function enrichLocation(location) {
  const query = parse(window.location.search);

  if (query.weather) {
    location = { ...location, condition: query.weather };
  }

  if (query.temperature) {
    location = { ...location, temperature: query.temperature };
  }

  if (query.uv) {
    location = { ...location, uv: query.uv };
  }

  if (query.aqi) {
    location = { ...location, air: query.aqi };
  }

  if (query.city) {
    location = { ...location, city: query.city };
  }

  if (query.postalCode) {
    location = { ...location, postalCode: query.postalCode };
  }

  if (query.country) {
    location = { ...location, country: query.country };
  }

  return location;
}