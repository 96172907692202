import { useEffect } from "react";

function ImageElement({ element, setCreativeState, creativeState = {}, visible }) {
  const { style, url, config = {} } = element;

  const { state = {} } = config;

  const { url: stateUrl } = state;

  const urlFromState = stateUrl && creativeState[stateUrl]

  useEffect(() => {
    if (!visible && urlFromState) {
      setCreativeState(prev => ({ ...prev, [stateUrl]: undefined}))
    }
  }, [visible, urlFromState, setCreativeState, stateUrl])

  if (!urlFromState && !url) {
    return <></>
  }

  return <img src={urlFromState || url} width={style.width} height={style.height} alt="" />;
}

export default ImageElement;
