import twemoji from "twemoji";

import { getTextContent } from "../helpers";

function TextElement({ element }) {
  const { style } = element;
  const text = getTextContent(element);
  const html = {
    __html: twemoji.parse(text, { folder: "svg", ext: ".svg" }),
  };

  return <div className="text" style={style} dangerouslySetInnerHTML={html} />;
}

export default TextElement;
