import { useRef, useEffect, useState } from "react";
import axios from "axios";
import { nanoid } from "nanoid";
import { drawCaptureCanvas, drawForegroundCanvas } from "helpers/cameraInput";
import { startCameraVideo } from "helpers";

function CameraInputElement({
  element,
  playing,
  visible,
  creativeState,
  setCreativeState,
  setActiveSlide,
}) {
  const cameraRef = useRef();
  const canvasRef = useRef();
  const { style, config } = element;
  const [imageCaptured, setImageCaptured] = useState(false);

  useEffect(() => {
    if (playing && visible && !imageCaptured) {
      const camera = cameraRef.current;
      const canvas = canvasRef.current;

      drawForegroundCanvas(camera, canvas, config);
      const interval = setInterval(
        () => drawForegroundCanvas(camera, canvas, config),
        config.refresh || 100
      );

      startCameraVideo(camera);
      setTimeout(async () => {
        clearInterval(interval);
        await drawCaptureCanvas(camera, canvas, config);

        canvas.toBlob(async (blob) => {
          const imageUuid = nanoid(12);
          const imageFilename = `${imageUuid}.png`;
          const formData = new FormData();

          formData.append("file", blob, imageFilename);
          const {
            data: { publicUrl: imageUrl },
          } = await axios.post(
            "https://image-upload-service-lihmnlumxq-no.a.run.app/upload-file-to-cloud-storage",
            formData
          );
          const { anchorToUpdate } = config;
          setImageCaptured(true);
          setCreativeState({
            ...creativeState,
            [`${anchorToUpdate}.imageUrl`]: imageUrl,
            [`${anchorToUpdate}.imageUuid`]: imageUuid,
          });
          setActiveSlide(config.nextSlide);
        });
      }, config.timer * 1000);
    }

    // Cuando se cambia de slide reseteamos imageCaptured para el siguiente ciclo
    if (!visible && imageCaptured) {
      setImageCaptured(false);
    }
  }, [
    config,
    playing,
    visible,
    creativeState,
    setActiveSlide,
    setCreativeState,
    imageCaptured,
  ]);

  return (
    <div style={style}>
      {imageCaptured ? (
        <img
          src={creativeState[`${config.anchorToUpdate}.imageUrl`]}
          alt="captured"
        />
      ) : (
        <canvas ref={canvasRef} width={style.width} height={style.height} />
      )}
      <video ref={cameraRef} style={{ display: "none" }} />
    </div>
  );
}

export default CameraInputElement;
