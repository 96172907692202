import axios from "axios";

const interactiveUrl = process.env.REACT_APP_D3CKSOCKET;

export const sendRegister = async (register) => {
    const { didWin, attempts, player = "No player" } = register;
    
    const res = await axios.post(`${interactiveUrl}/reports`, {
        didWin, 
        attempts, 
        player
      });
    console.log(`Register successfully`, res);
}