export const localStorageItem = "cityportal-app";
export const getToken = () => localStorage.getItem(localStorageItem);

export const headers = (contentType = "application/json") => {
  if (contentType) {
    return {
      "Content-Type": contentType,
      Accept: "application/json",
      Authorization: `JWT ${getToken()}`,
    };
  }

  return {
    Accept: "application/json",
    Authorization: `JWT ${getToken()}`,
  };
};
