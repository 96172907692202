import { useEffect, useRef, useState, useCallback } from "react"
import { startCameraVideo } from "helpers"
import {
  HandLandmarker,
  FilesetResolver,
  GestureRecognizer,
} from "@mediapipe/tasks-vision"
import { useLogger } from "providers/LoggerProvider"
import "./PoseDetectionElement.css"

function PoseDetectionElement({ element, playerId, onAction, isVisible }) {
  const { sendLog } = useLogger();
  const [videoReady, setVideoReady] = useState(false)
  const videoRef = useRef()
  const canvasRef = useRef()
  const [currentHand, setCurrentHand] = useState("None")
  const { style } = element
  const intervalId = useRef();

  useEffect(() => {
    async function init() {
      try {
        await startCameraVideo(videoRef.current)
        setVideoReady(true)
      } catch (err) {
        console.error("Error iniciando video:", err)
      }
    }

    init()
  }, [])

  async function createGestureRecognizer() {
    try {
      const vision = await FilesetResolver.forVisionTasks("/wasm/")

      const gestureRecognizer = await GestureRecognizer.createFromOptions(
        vision,
        {
          baseOptions: {
            modelAssetPath: `/pose-model/gesture_recognizer.task`,
          },
          runningMode: "VIDEO",
          numHands: 1,
        }
      )

      return gestureRecognizer
    } catch (err) {
      console.error("Error creando hand landmarker:", err)
    }
  }

  const detectHands = useCallback(async (gestureRecognizer) => {
    const video = videoRef.current
    if (!video || !video.currentTime) return

    if (!isVisible) {
      clearInterval(intervalId.current);
      intervalId.current = null;
      return;
    }

    if (intervalId.current) {
      return;
    }

    intervalId.current = setInterval(async () => {
      try {
        const hands = gestureRecognizer.recognizeForVideo(
          video,
          video.currentTime
        )

        if (hands.gestures[0]) {
          const CHANGE_VIDE_DELAY = style.display === 'none' ? 0 : 1000

          setCurrentHand(hands.gestures[0][0].categoryName)
  
          // TODO: setTimeout para que se muestre el mensaje de gesto mientras esté la creatividad en prueba.
          setTimeout(() => {
            if (hands.gestures[0][0].categoryName === 'Open_Palm') {
              isVisible && onAction()
    
              try {
                sendLog(`Face detected ${JSON.stringify(hands.gestures[0][0].categoryName)} on player ${playerId}`)
              }catch (error) {
                sendLog(`Error parsing face detection`)
              }
            } else {
              sendLog(`No gesture detected`)
            }
          }, CHANGE_VIDE_DELAY);
         
        }
      } catch (error) {
        console.error("Error detecting hands:", error)
      }
    }, 500)
  }, [isVisible, onAction, playerId, sendLog])

  useEffect(() => {
    async function setupHandDetection() {
      try {
        const gestureRecognizer = await createGestureRecognizer()
        if (gestureRecognizer) {
          detectHands(gestureRecognizer)
        }
      } catch (err) {
        console.error("Error configurando detección de manos:", err)
      }
    }

    if (videoReady) {
      setupHandDetection()
    }
  }, [videoReady, detectHands])

  return (
    <div style={style}>
      <p className="gesture-text">{currentHand}</p>
        <video
          ref={videoRef}
          style={{ transform: "scaleX(-1)" }}
        />
    </div>
  )
}

export default PoseDetectionElement
