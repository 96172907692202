import classNames from "classnames";
import { useEffect } from "react";

import "./InputElement.css";

function InputElement({ element, creativeState = {}, setCreativeState, playing, visible }) {
  const { style, anchor, config = {} } = element;

  const { name, type, cssClasses = [] } = config;

  const isChecked = creativeState[`${anchor}.checked`];

  function changeHandler(event) {
    setCreativeState({ ...creativeState, [`${anchor}.checked`]: event.target.checked })
  }
  
  useEffect(() => {
    if (!(playing && visible)) return setCreativeState((prev) => ({ ...prev, [`${anchor}.checked`]: false }));
  }, [anchor, setCreativeState, playing, visible])

  return <input type={type} id={name} name={name} style={ style } onChange={changeHandler} className={classNames('input', cssClasses)} checked={isChecked} />;
}

export default InputElement;
