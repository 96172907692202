export default function startCameraVideo(camera) {
  if (navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        camera.srcObject = stream;
        return camera.play();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
