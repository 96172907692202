import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { startCameraVideo } from "helpers";
import {
  loadFaceApiModels,
  getDetection,
  getMarkStyles,
  drawCanvas,
  hasBoxChangedSize,
  logDetection,
  getMarksOpacity,
  getContainerClassName,
} from "helpers/faceTracker";
import { Mark } from "assets/fifa";
import { useLogger } from "providers/LoggerProvider";

import "./FaceTrackerElement.css";

function FaceTrackerElement({ element, player = {} }) {
  const [videoReady, setVideoReady] = useState(false);
  const [markStyles, setMarkStyles] = useState(null);
  const [noDetection, setNoDetection] = useState(true);
  const [transitionDone, setTransitionDone] = useState(false);
  const videoRef = useRef();
  const canvasRef = useRef();
  const { sendLog } = useLogger();
  const { style, mirror = true, rotation = 90 } = element;

  useEffect(() => {
    async function drawVideo() {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      drawCanvas({ canvas, video, mirror, rotation, style });
    }

    let intervalId;

    async function init() {
      await loadFaceApiModels();
      await startCameraVideo(videoRef.current);
      setVideoReady(true);
      intervalId = setInterval(() => drawVideo());
    }

    init();

    return () => {
      clearInterval(intervalId);
    };
  }, [mirror, rotation, style]);

  useEffect(() => {
    const DRAW_MARK_DELAY = 50;
    let intervalId;
    const detectionIds = [];

    async function drawMark() {
      const TRANSITION_DURATION = 1000;
      const canvas = canvasRef.current;
      const detection = await getDetection(canvas);

      if (!detection) {
        const detectionId = setTimeout(() => {
          setMarkStyles(null);
          setNoDetection(true);
          setTransitionDone(false);
        }, 500);

        detectionIds.push(detectionId);
        return;
      }

      detectionIds.forEach((detectionId) => clearTimeout(detectionId));

      if (noDetection) {
        setNoDetection(false);

        setTimeout(() => setTransitionDone(true), TRANSITION_DURATION);
      }

      if (
        !markStyles ||
        hasBoxChangedSize(detection.box, markStyles, style.height)
      ) {
        setMarkStyles(getMarkStyles(detection.box, style.height));
        logDetection(detection, sendLog, player.bsPlayerId);
        return;
      }

      const { left, bottom } = getMarkStyles(detection.box, style.height);

      setMarkStyles({
        left,
        bottom,
        width: markStyles.width,
      });
      logDetection(detection, sendLog, player.bsPlayerId);
    }

    if (videoReady) {
      intervalId = setInterval(() => drawMark(), DRAW_MARK_DELAY);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    style.height,
    videoReady,
    markStyles,
    player.bsPlayerId,
    sendLog,
    noDetection,
  ]);

  const { markOpacity, markTransitionOpacity } = getMarksOpacity(
    noDetection,
    transitionDone
  );

  return (
    <div style={style}>
      <video className="face-tracker-video" ref={videoRef} />
      <canvas ref={canvasRef} width={style.width} height={style.height} />
      <div className={classNames(getContainerClassName(noDetection))}>
        <div className="filter"></div>
        <img
          className="mark"
          src={Mark}
          alt="FC24"
          style={{ ...markStyles, opacity: markOpacity }}
        />
        <img
          className="mark-transition"
          src={Mark}
          alt="FC24-transition"
          style={{ ...markStyles, opacity: markTransitionOpacity }}
        />
      </div>
    </div>
  );
}

export default FaceTrackerElement;
