import QRCode from 'qrcode.react';

export default function QRCodeElement({ element, creativeState = {}, player = {} }) {
  const { style, url: baseUrl, config } = element;

  const url = new URL(baseUrl);

  const { state = {}, params: playerQueryParams } = config;

  const { params: stateQueryParams = [] } = state;


  stateQueryParams.forEach(param => {
    const value = creativeState[param.value]
 
    url.searchParams.append(param.key, value)
  })

  playerQueryParams.forEach(param => {
    const value = player[param.value]

    url.searchParams.append(param.key, value)
  })

  return (
    <div>
      <QRCode
        value={url.toString()}
        size={style.width}
        renderAs="svg"
        bgColor="white"
        fgColor={style.color}
      />
    </div>
  );
}
