import { getTextContent } from "../helpers";

import "./ButtonElement.css";

function ButtonElement({ element, setActiveSlide, creativeState = {} }) {
  const { style, targetSlide, config = {} } = element;

  const { state = {} } = config;

  const { disabled: stateDisabled } = state;

  const disabled = stateDisabled !== undefined && !creativeState[stateDisabled]

  const text = getTextContent(element);
  return <button className="button" style={style} onClick={() => setActiveSlide(targetSlide)} disabled={disabled} >{text}</button>;
}

export default ButtonElement;
