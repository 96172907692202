import React from "react";
import { Route, Router } from "wouter";
import InteractivePlayer from "./InteractivePlayer";
import ErrorBoundary from "./ErrorBoundary";
import { LoggerProvider } from "./providers/LoggerProvider";

function App() {
  return (
    <ErrorBoundary>
      <LoggerProvider>
        <Router base={"/interactive"}>
          <Route path={`/:creativeId`} component={InteractivePlayer} />
        </Router>
      </LoggerProvider>
    </ErrorBoundary>
  );
}

export default App;
